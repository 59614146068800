import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useTranslation } from 'next-i18next';
import { Link } from "@/helpers/common_helper";
export default function Index() {
  const { t } = useTranslation('common');

  return (
    <>
      <div style={{textAlign: 'center', height: '500px',display: 'flex',flexDirection: 'column',justifyContent: 'center',alignItems: 'center'}}>
        <p style={{fontSize: '30px',fontWeight: '800'}}>{t('404 Page Not Found')}.</p>
        <Link href="/">
            <button style={{width: '30%',borderRadius: '10px', height: '30px',background: '#000',color: '#fff',marginTop: '15px'}}>
                {t('Back To Home')}
            </button>
        </Link>
      </div>
    </>
  )
}

export async function getStaticProps({ locale, params }) {
  return {
    props: {
      ...params,
      ...(await serverSideTranslations(locale, ['common'])),
    },
  };
}
